import React, { useEffect, useState } from 'react';
import { formatDate, convertTimeToSeconds } from './utils';
import ActivitySelection from './ActivitySelection';
import ActivityStats from './ActivityStats';
import MapRenderer from './MapRenderer';
import L from 'leaflet';
import ElevationChart from './ElevationChart';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; 
import VeloVinoNavigation from './VeloVinoNavigation';

const Activity = () => {
  const [fromPage, setFromPage] = useState(null);  // Store fromPage in state
  const location = useLocation();  // Get location object
  const searchParams = new URLSearchParams(location.search);
  const initialFromPage = searchParams.get('fromPage');  

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  // Set the fromPage state on initial render
  useEffect(() => {
    if (!fromPage) {
      setFromPage(initialFromPage);  // Set fromPage only on first load
    }
  }, [initialFromPage, fromPage]);

  const { id } = useParams(); 
  const [activities, setActivities] = useState([]);
  const [garminStats, setGarminStats] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [activityPoints, setActivityPoints] = useState([]);
  const [scrollToActivity, setScrollToActivity] = useState(false); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch activities from the API
    fetch(`${apiBaseUrl}/activities`)
      .then(response => response.json())
      .then(data => {
        const updatedData = data.map(activity => {
          const dateObject = new Date(activity.date);

          return {
            ...activity,
            name: activity.name ? activity.name.replace("Cycling", "").trim() : "Unnamed Activity",
            displayName: `${formatDate(dateObject, 'list')} - ${activity.name.replace("Cycling", "").trim()}`,
            selectedDisplayName: `${activity.name.replace("Cycling", "").trim()}`,
            selectedDate: dateObject,
          };
        });

        setActivities(updatedData);
        
        // Select the activity based on the ID from the URL
        const selectedActivity = updatedData.find(activity => activity.id.toString() === id);
        if (selectedActivity) {
          setSelectedActivities([selectedActivity.id]);
          setLastSelectedIndex(updatedData.indexOf(selectedActivity));

          setScrollToActivity(true); 

          navigate('/velovino/activities', { replace: true }); // Update the URL without adding to the history
        } else if (updatedData.length > 0) {
          setSelectedActivities([updatedData[0].id]);
          setLastSelectedIndex(0);

          setScrollToActivity(false);

          navigate('/velovino/activities', { replace: true }); // Update the URL without adding to the history
        }
      })
      .catch(error => console.error('Error fetching activities:', error));

    // Fetch Garmin stats from the API
    fetch(`${apiBaseUrl}/garmin-stats`)
      .then(response => response.json())
      .then(data => {
        setGarminStats(data);
      })
      .catch(error => console.error('Error fetching Garmin stats:', error));
  }, []);

  // Fetch activity points when selectedActivities changes
  useEffect(() => {
    if (selectedActivities.length > 0) {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const activityIds = selectedActivities.join(',');
      
      fetch(`${apiBaseUrl}/activity-points?activity_ids=${activityIds}`)
        .then(response => response.json())
        .then(data => {
          setActivityPoints(data);
        })
        .catch(error => console.error('Error fetching activity points:', error));
    }
  }, [selectedActivities]);

 // If the data is still loading, display "Loading..."
 if (!activities.length || !activityPoints.length || !garminStats.length) {
  return (
    <section id="journey" className="content">
      <div className='content-header'></div>

      <div className='loading'>
        <div className='loading-text'>Loading...</div>
      </div>
    </section>
  );
}

  // Function to set scrollToActivity to false after scrolling
  const setScrollToActivityFalse = () => {
    setScrollToActivity(false); // This will reset scrollToActivity
  };

  // Merge Garmin stats and points with activities
  const mergedActivities = activities.map(activity => {
    const stats = garminStats.find(stat => stat.ID === activity.id);
    const points = activityPoints
      .filter(point => point.activity_id === activity.id)
      .map(point => [point.lat, point.lon])
      .filter(point => point[0] !== undefined && point[1] !== undefined);
    return {
      ...activity,
      stats: stats || {},
      points,
    };
  });

  // Handle activity selection  
  const handleActivityClick = (event, activityId, index, filteredActivities) => {
    const isCtrlPressed = event.ctrlKey || event.metaKey;
    const isShiftPressed = event.shiftKey;

    // Use the filteredActivities list for Shift selection
    if (isShiftPressed && lastSelectedIndex !== null) {
        const rangeStart = Math.min(lastSelectedIndex, index);
        const rangeEnd = Math.max(lastSelectedIndex, index);
        const newSelections = filteredActivities.slice(rangeStart, rangeEnd + 1).map(activity => activity.id);

        setSelectedActivities(Array.from(new Set([...selectedActivities, ...newSelections])));
    } else if (isCtrlPressed || event.target.type === 'checkbox') {
        if (selectedActivities.includes(activityId)) {
            setSelectedActivities(selectedActivities.filter(id => id !== activityId));
        } else {
            setSelectedActivities([...selectedActivities, activityId]);
        }
    } else {
        setSelectedActivities([activityId]);
    }

    setLastSelectedIndex(index); 
};



  const selectedActivitiesDetails = mergedActivities.filter(activity => selectedActivities.includes(activity.id));

  // Calculate the stats
  let totalDistance = 0;
  let totalElevation = 0;
  let totalDuration = 0;
  let maxSpeed = null;
  let maxHeartRate = null;
  let maxTemperature = null;

  if (selectedActivitiesDetails.length > 0) {
    selectedActivitiesDetails.forEach(activity => {
      totalDistance += parseFloat(activity.stats.Distance) || 0;
      totalElevation += parseFloat(activity.stats.TotalAscent) || 0;
      totalDuration += convertTimeToSeconds(activity.stats.MovingTime || '00:00:00');

      if (activity.stats.BestPace && (maxSpeed === null || parseFloat(activity.stats.BestPace) > maxSpeed)) {
        maxSpeed = parseFloat(activity.stats.BestPace);
      }
      if (activity.stats.MaxHR && (maxHeartRate === null || parseFloat(activity.stats.MaxHR) > maxHeartRate)) {
        maxHeartRate = parseFloat(activity.stats.MaxHR);
      }
      if (activity.stats.MaxTemp && (maxTemperature === null || parseFloat(activity.stats.MaxTemp) > maxTemperature)) {
        maxTemperature = parseFloat(activity.stats.MaxTemp);
      }
    });
  }

  // Filter activityPoints to only include points for selected activities
  const filteredActivityPoints = activityPoints.filter(point => selectedActivities.includes(point.activity_id));

  // Define the icons
  const bikeIcon = new L.Icon({
    iconUrl: '/icons/marker_bike_blue.png',  // Ensure this path is correct
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -35]
  });

  const bikeIconGreen = new L.Icon({
    iconUrl: '/icons/marker_bike_green.png',  // Ensure this path is correct
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    popupAnchor: [0, -50]
  });

  const bikeIconRed = new L.Icon({
    iconUrl: '/icons/marker_bike_red.png',  // Ensure this path is correct
    iconSize: [56, 56],
    iconAnchor: [28, 56],
    popupAnchor: [0, -42]
  });

  // Prepare markers and polyline groups
  const markers = selectedActivitiesDetails.flatMap((activity, idx) => {
    if (activity.points.length === 0) return [];

    const isFirstActivity = idx === 0;
    const isLastActivity = idx === selectedActivitiesDetails.length - 1;

    return [
      {
        position: activity.points[0],
        icon: isFirstActivity ? bikeIconGreen : bikeIcon,
        name: activity.selectedDisplayName,
        date: activity.selectedDate,
      },
      isLastActivity ? {
        position: activity.points[activity.points.length - 1],
        icon: bikeIconRed,
        name: activity.selectedDisplayName,
        date: activity.selectedDate,
      } : null
    ].filter(marker => marker !== null);
  });

  const polylineGroups = selectedActivitiesDetails.map(activity => ({
    points: activity.points,
    color: "#205CA4",
    weight: 3,
    opacity: 0.8,
}));

  return (
    <section id="activity" className="content">
        <div className="content-header"></div>
        <div className='content-header-text velo-vino-header'>
        <div className='velo-vino-header-text'>Cycling Activities</div>
          <VeloVinoNavigation fromPage={fromPage} />
        </div>
        <div className='div-spacer-large'></div>
        <div className="activity-details">
            <ActivitySelection 
                activities={mergedActivities} 
                selectedActivities={selectedActivities} 
                setSelectedActivities={setSelectedActivities} // Pass this down to update selected activities
                handleActivityClick={handleActivityClick} 
                isMobile={window.innerWidth <= 900} // Pass isMobile prop based on window width
                scrollToActivity={scrollToActivity}
                setScrollToActivityFalse={setScrollToActivityFalse}
            />
            <div className='column-centred'>
              <ActivityStats 
                  selectedActivitiesDetails={selectedActivitiesDetails} 
                  totalDistance={totalDistance} 
                  totalElevation={totalElevation} 
                  totalDuration={totalDuration} 
                  maxSpeed={maxSpeed} 
                  maxHeartRate={maxHeartRate} 
                  maxTemperature={maxTemperature} 
              />
            </div>
          </div>
          <div className='div-spacer-activities'>&nbsp;</div>
          <div className='activity-map'>
              {selectedActivitiesDetails.length > 0 && (
                <MapRenderer 
                    markers={markers}
                    polylineGroups={polylineGroups}
                    popupContent={(item) => (
                    <>
                      <strong>{item.name}</strong><br />
                      {item.date && formatDate(item.date, 'selected')}
                    </>
                  )}
                />
              )}
           </div>
           <div className='chart-display'>
              <h2>Elevation Profile</h2>
              <ElevationChart 
                activityPoints={filteredActivityPoints} 
                activities={activities}
                downsampleInterval={5} 
                normalisedElevation={false} 
              />
            </div>           
    </section>
  );
};

export default Activity;
