import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; // Import React Router components
import Header from './components/Header';
import Home from './components/Home';
import VeloVino from './components/VeloVino';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Journey from './components/Journey';
import Activities from './components/Activities'; // Component for activities
import About from './components/About';

const App = () => {
  // Ensure that useLocation is called inside the component
  const location = useLocation(); // Get the current path using useLocation
  
  // Define getContainerClassName inside the component
  const getContainerClassName = () => {
    const { pathname } = location;

    if (pathname === '/about') {
      return 'container';
    } else if (pathname === '/contact') {
      return 'container container-contact';
    } else if (pathname === '/velovino') {
      return 'container container-velovino';
    } else {
      return 'container container-full';
    }
  };

  return (
    <div className={getContainerClassName()}>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/velovino" element={<VeloVino />} />
          <Route path="/velovino/journey" element={<Journey />} />
          <Route path="/velovino/activities/" element={<Activities />} /> 
          <Route path="/velovino/activities/:id" element={<Activities />} /> {/* Dynamic activity route */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
