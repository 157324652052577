import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconBackToVeloVino, IconBackToJourney, IconForwardToActivities, IconToVeloVino } from './Icons';

const VeloVinoNavigation = ({ fromPage, currentPage}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      // Go back in history if possible
      navigate(-1);
    } else {
      // If no history, navigate to the home page
      navigate('/');
    }
  };

  const handleActivitiesClick = () => {
      navigate('/velovino/activities?fromPage=Journey');
  };

  const handlVeloVinoClick = () => {
    navigate('/velovino');
};

  const renderIcon = () => {
    if (fromPage === "VeloVino") {
      return (
        <>
          <div className="velovino-nav" >
            <div className='velovino-nav-box'>
              <div className="velovino-nav-tooltip-container" onClick={handleBackClick} >
                <IconBackToVeloVino width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
                <span className="velovino-nav-tooltip-text">Back to Velo Vino</span>
              </div>
              {currentPage === "Journey" && (
                <>
                  <div width='10px'>&nbsp;</div>       
                  <div className="velovino-nav-tooltip-container" onClick={handleActivitiesClick} >
                    <IconForwardToActivities width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
                    <span className="velovino-nav-tooltip-text">Go to Activities</span>
                  </div>
                </>
              )}      
            </div>
          </div>
        </>
      );
    } else if (fromPage === "Journey") {
      return (
        <div className="velovino-nav" >
          <div className="velovino-nav-tooltip-container" onClick={handleBackClick} >
            <IconBackToJourney width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
            <span className="velovino-nav-tooltip-text">Back to Journey</span>
          </div>
        </div>
      );
    } else {
      if (currentPage === "Journey") {
        return (
          <>
            <div className="velovino-nav">
              <div className='velovino-nav-box'>
                <div className="velovino-nav-tooltip-container" onClick={handlVeloVinoClick}>
                  <IconToVeloVino width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
                  <span className="velovino-nav-tooltip-text">About Velo Vino</span>
                </div>  
                <div className="velovino-nav-tooltip-container" onClick={handleActivitiesClick}>
                  <IconForwardToActivities width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
                  <span className="velovino-nav-tooltip-text">Go to Activities</span>
                </div>
              </div>
            </div>
          </>
        );
      }
      else {
        return (
          <div className="velovino-nav" onClick={handlVeloVinoClick}>
            <div className="velovino-nav-tooltip-container" >
              <IconToVeloVino width={75} height={30} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-velovino-nav'} />
              <span className="velovino-nav-tooltip-text">About Velo Vino</span>
            </div>  
          </div>  
        );      
      };   
     }
  };

  return (    
    <>    
      <div>
        {renderIcon()}
      </div>
    </>
  );
};

export default VeloVinoNavigation;
