import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setErrors((prevErrors) => ({ ...prevErrors, captcha: null }));
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!captchaValue) {
      setErrors({ captcha: 'Please complete the CAPTCHA' });
      return;
    }

    // Include the captcha token in the form data
    const submissionData = { ...formData, captchaToken: captchaValue };

    // Submit form
    try {
      const mailUrl = process.env.REACT_APP_MAIL_URL;

      await fetch(mailUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      setSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
      setErrors({});
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <div className="contact-form-container">
      {submitted ? (
        <div className='contact-form-thank-you'>Thank you for your message! I'll be in touch soon.</div>
      ) : (
        <>
          <div className='contact-form-message'>To get in touch, please complete the form below.</div>

          <div className="contact-form-box">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Enter your Name:</label>
                <input
                  className="form-input"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Enter your Email:</label>
                <input
                  className="form-input"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>

              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  className="form-textarea"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  required
                />
                {errors.message && <p className="error">{errors.message}</p>}
              </div>

              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LcoMEwqAAAAANPsPAq5e34QetK7GN1tHzdl_085" 
                  onChange={handleCaptchaChange}
                />
                {errors.captcha && <p className="error">{errors.captcha}</p>}            
              </div>

              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactForm;
