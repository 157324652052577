import React from 'react';
import { formatDate, formatDateRange, convertSecondsToTime } from './utils';
import { IconBike, IconHeartRate, IconSpeed, IconTemp, IconElevation, IconDuration } from './Icons';

const ActivityStats = ({ selectedActivitiesDetails, totalDistance, totalElevation, totalDuration, maxSpeed, maxHeartRate, maxTemperature }) => {
  if (selectedActivitiesDetails.length === 0) {
    return <div>No activities selected</div>;
  }

  // Normalise the times of both dates to midnight (00:00:00)
  const normaliseToDate = (date) => {
    const normalisedDate = new Date(date);
    normalisedDate.setHours(0, 0, 0, 0); // Set time to midnight
    return normalisedDate;
  };

  const firstActivity = selectedActivitiesDetails[0];
  const lastActivity = selectedActivitiesDetails[selectedActivitiesDetails.length - 1];

  // Calculate the duration by counting the calendar days (date difference + 1 day to include both start and end days)
  const duration = Math.ceil((normaliseToDate(lastActivity.selectedDate) - normaliseToDate(firstActivity.selectedDate)) / (1000 * 60 * 60 * 24));
  const dateRangeText = formatDateRange(firstActivity.selectedDate, lastActivity.selectedDate);
  const nameText = firstActivity.selectedDisplayName === lastActivity.selectedDisplayName
    ? firstActivity.selectedDisplayName
    : `${firstActivity.selectedDisplayName} to ${lastActivity.selectedDisplayName}`;
  const formattedDuration = convertSecondsToTime(totalDuration);

  // Format distance and elevation with commas if they're over 1,000
  const formattedDistance = totalDistance >= 1000 
    ? totalDistance.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) 
    : totalDistance.toFixed(0);

  const formattedElevation = totalElevation >= 1000 
    ? totalElevation.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) 
    : totalElevation.toFixed(0);

  // Define stats data
  const stats01 = [
    { id: 'statsDistance', text: `${formattedDistance} km`, desc: 'cycled', scale: 0.8, translatex: 31.5, translatey: -10, strokeWidth: 0.8, className : 'stat-icon', IconComponent: IconBike },
    { id: 'statsDuration', text: formattedDuration, desc: 'pedalling', scale: 0.65, translatex: 38, translatey: -8, strokeWidth: 0, className : 'stat-icon', IconComponent: IconDuration },
    { id: 'statsElevation', text: `${formattedElevation} m`, desc: 'climbed', scale: 0.6, translatex: 39, translatey: -8, strokeWidth: 1.5, className : 'stat-icon', IconComponent: IconElevation },
  ];

  const stats02 = [
    { id: 'statsMaxHR', text: maxHeartRate !== null ? `${maxHeartRate} bpm` : 'N/A', desc: 'max hr', scale: 0.55, translatex: 40.7, translatey: -1, strokeWidth: 2, className : 'stat-icon', IconComponent: IconHeartRate },
    { id: 'statsMaxSpeed', text: maxSpeed !== null ? `${maxSpeed.toFixed(1)} km/h` : 'N/A', desc: 'max speed', scale: 0.6, translatex: 38.5, translatey: -4, strokeWidth: 1, className : 'stat-icon', IconComponent: IconSpeed },
    { id: 'statsMaxTemp', text: maxTemperature !== null ? `${maxTemperature} °C` : 'N/A', desc: 'max temp', scale: 0.5, translatex: 40, translatey: 1, strokeWidth: 1, className : 'stat-icon', IconComponent: IconTemp },
  ];

  return (
    <div className='activity-stats'>
      <div className="activity-title">
        {selectedActivitiesDetails.length === 1 ? (
          <>
            <div className='activity-name'>{firstActivity.selectedDisplayName}</div>
            <div className='activity-date'>{formatDate(firstActivity.selectedDate, 'selected')}</div>
          </>
        ) : selectedActivitiesDetails.length > 1 ? (
          <>
            <div className='activity-name'>{nameText}</div>
            <div className='activity-date'>
              {duration}&nbsp;
              {duration === 1 ? 'day' : 'days'}&nbsp; - {dateRangeText}
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>

      {selectedActivitiesDetails.length > 0 && (
        <>
          <div className="activity-stats-details">
            <div className='stats-group'>
              {stats01.map((stat) => (
                <div key={stat.id} className="column-centred">
                  <div className="stats-image-small">
                    <div className='svg-icon svg-icon-small'>
                      <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
                    </div>
                    <div className="stat-text-small stat-text-small-value">{stat.text}</div>
                    <div className="stat-text-small">{stat.desc}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className='stats-group'>
              {stats02.map((stat) => (
                <div key={stat.id} className="column-centred">
                  <div className="stats-image-small">
                    <div className='svg-icon svg-icon-small'>
                      <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
                    </div>
                    <div className="stat-text-small stat-text-small-value">{stat.text}</div>
                    <div className="stat-text-small">{stat.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>        
      )}
    </div>
  );
};

export default ActivityStats;
