import React from 'react';
import { Link } from 'react-router-dom'; 

const About = () => (
  <section id="contact" className="content">
    <div className='content-header'>
    </div>
    <div className='content-header-text'>
        About
    </div>
    <div className='about-me box-container'>
      <div className='about-me-text'>
        Kia ora! I'm Matt Watson, a technology consultant with over 20 years of experience in data, analytics, and digital development and delivery. Throughout my career, I’ve worked across a range of industries, including financial services, logistics, education, and beyond, partnering with organisations at all stages of growth—from start-ups and scale-ups to large enterprises like IAG, UBS, and the University of Auckland.        
        <br /><br />
        My strength lies in strategic development and delivering practical, results-driven solutions. Whether it’s building data governance frameworks, implementing analytics platforms, or driving digital transformation, my focus is on helping businesses turn technology challenges into opportunities for growth. I take pride in connecting complex technical strategies with real-world outcomes that align with your business objectives.        
        <br /><br />
        A year ago, my wife and I embarked on a 4.5-month cycling tour through Europe, which gave me the time and space to reflect on how I could best contribute to the success of Kiwi businesses. That reflection led to the creation of Blue Spoke, where I combine my experience and passion for problem-solving to help organisations navigate the complexities of data and technology.
        <br /><br />
        Whether you're looking to unlock more value from your data, streamline your digital operations, or benefit from some fractional CTO support, I’m here to help. I offer flexible engagement options tailored to your business needs, no matter the size or scope. If you'd like to explore how I can help drive your business forward, 
        please <Link to="/Contact" className='link-text'> get in touch</Link>.
        <br /><br />
        <a href='https://www.linkedin.com/in/matt-watson-nz/' target='_blank' rel='noreferrer' >
            <img src="/images/linkedIn_logo.png" alt="LinkedIn - Matt Watson" className='instagram-logo' />
          </a>
      </div>
      <div className='about-me-image-box'>
      <picture title='Matt Watson'>
          <source srcSet="/images/MattProfile.webp" type="image/webp" />
          <source srcSet="/images/MattProfile.png" type="image/png" />
          <img src="/images/MattProfile.png" alt="Matt Watson" className="about-me-image" />
        </picture>
      </div>
    </div>
  </section>
);

export default About;
