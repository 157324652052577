import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Scrolling to top");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // Optional: Adds smooth scrolling animation
      });
    }, 100);  // Add a slight delay to ensure scroll happens after rendering
  }, [location.pathname]);
};

export default useScrollToTop;
