import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconBike, IconHeartRate, IconSpeed, IconTemp, IconElevation, IconDuration } from './Icons';

const JourneyStats = () => {
  const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0, path: '' });
  const navigate = useNavigate();  // Initialize useNavigate

  // Utility function to detect if the user is on a mobile device
  const isMobile = () => {
    return window.innerWidth <= 900;
  };

  const handleMouseEnter = (event, text, id, activity_id) => {
    setTooltip({
      show: true,
      text: text,
      id: id,  
      activity_id: activity_id,
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ show: false, text: '', id: null, activity_id: null });
  };

  const handleClick = (activity_id) => {
    if (isMobile()) {
      // Do nothing on mobile devices
      return;
    }

    if (activity_id) {
      navigate(`/velovino/activities/${activity_id}?fromPage=Journey`);  // Use React Router to navigate to the internal path
    }
  };

  const handleTooltipClick = (activity_id) => {
    console.log(`tooltip click: `)
    if (!isMobile()) {
      // Do nothing on desktop devices
      return;
    }

    if (activity_id) {
      navigate(`/velovino/activities/${activity_id}?fromPage=Journey`);  // Use React Router to navigate to the internal path
    }
  };


  const stats01 = [
    { id: 'statsDistance', text: '2,523 km', desc: 'cycled', tooltip:'Longest Ride: Linz', activity_id: 60, scale: 0.8, translatex: 31.5, translatey: -10, strokeWidth: 0.8, className : 'stat-icon', IconComponent: IconBike },
    { id: 'statsDuration', text: '4.5 months', desc: '', tooltip:'Longest Day: Regensburg', activity_id: 56, scale: 0.65, translatex: 38, translatey: -8, strokeWidth: 0, className : 'stat-icon', IconComponent: IconDuration },
    { id: 'statsElevation', text: '12,795 m', desc: 'climbed', tooltip:'Highest Climb: Orsingen-Nenzingen', activity_id: 46, scale: 0.6, translatex: 39, translatey: -8, strokeWidth: 1.5, className : 'stat-icon', IconComponent: IconElevation },
  ];

  const stats02 = [
    { id: 'statsMaxHR', text: '184 bpm', desc: 'max hr', tooltip:'Highest HR: Monaco Gran Prix', activity_id: 25, scale: 0.55, translatex: 40.7, translatey: -1, strokeWidth: 2, className : 'stat-icon', IconComponent: IconHeartRate },
    { id: 'statsMaxSpeed', text: '55.6 km/h', desc: 'max speed', tooltip:'Highest Speed: Arco', activity_id: 91, scale: 0.6, translatex: 38.5, translatey: -4, strokeWidth: 1, className : 'stat-icon', IconComponent: IconSpeed },
    { id: 'statsMaxTemp', text: '40 °C', desc: 'max temp', tooltip:'Highest Temp: Niederschonenfeld', activity_id: 53, scale: 0.5, translatex: 40, translatey: 1, strokeWidth: 1, className : 'stat-icon', IconComponent: IconTemp },
  ];

  return (
    <>
    <div className="stats-row">
      <div className='stats-group'>
        {stats01.map((stat) => (
          <div key={stat.id} className="column-centred">
            <div className="stats-image-wrapper" >
              <div className="stats-image-medium"
                onMouseEnter={(e) => handleMouseEnter(e, stat.tooltip, stat.id, stat.activity_id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(stat.activity_id)}  // Attach the click event to the div
                style={{ cursor: 'pointer' }} 
              >
                <div className='svg-icon svg-icon-medium'>
                  <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
                </div>
                <div className="stat-text-medium stat-text-medium-value">{stat.text}</div>
                <div className="stat-text-medium">{stat.desc}</div>
              </div>
              {tooltip.show && tooltip.id === stat.id && (
                <div className="journey-stats-tooltip" 
                onClick={() => handleTooltipClick(tooltip.activity_id)} 
                style={{ cursor: 'pointer' }} 
              >
                {tooltip.text}
              </div>
            )}   
            </div>
          </div>
        ))}
      </div>
      <div className='stats-group'>
        {stats02.map((stat) => (
          <div key={stat.id} className="column-centred">
            <div className="stats-image-wrapper" >
              <div className="stats-image-medium"
                onMouseEnter={(e) => handleMouseEnter(e, stat.tooltip, stat.id, stat.activity_id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(stat.activity_id)}  // Attach the click event to the div
                style={{ cursor: 'pointer' }} 
              >
                <div className='svg-icon svg-icon-medium'>
                  <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
                </div>
                <div className="stat-text-medium stat-text-medium-value">{stat.text}</div>
                <div className="stat-text-medium">{stat.desc}</div>
              </div>
              {tooltip.show && tooltip.id === stat.id && (
                <div className="journey-stats-tooltip" 
                  onClick={() => handleTooltipClick(tooltip.activity_id)} 
                  style={{ cursor: 'pointer' }} 
                >
                  {tooltip.text}
                </div>
              )}   
            </div>
          </div>
        ))}
      </div>
    </div>
 
    </>
  );
};
  
export default JourneyStats;
