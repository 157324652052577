import React from 'react';
import { Link } from 'react-router-dom';

export const formatDate = (dateObject, type) => {
    if (type === 'list') {
      // Format for the select list (dd/mm/yy)
      return dateObject.toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
    } else if (type === 'selected') {
      // Format for the selected activity (dd mmm yyyy)
      const day = dateObject.getDate();
      const month = dateObject.toLocaleDateString('en-GB', { month: 'short' });
      const year = dateObject.getFullYear();
  
      const dayWithOrdinal = day + getOrdinalSuffix(day);
      return `${dayWithOrdinal} ${month} ${year}`; // e.g., "1st Jan 2023"
    }
    return '';
  };
  
  export const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // covers 11th, 12th, 13th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  export const formatDateRange = (firstDate, lastDate) => {
    const sameDay = firstDate.getDate() === lastDate.getDate();
    const sameMonth = firstDate.getMonth() === lastDate.getMonth();
    const sameYear = firstDate.getFullYear() === lastDate.getFullYear();
  
    const firstDayWithOrdinal = firstDate.getDate() + getOrdinalSuffix(firstDate.getDate());
    const lastDayWithOrdinal = lastDate.getDate() + getOrdinalSuffix(lastDate.getDate());
  
    const firstMonth = firstDate.toLocaleDateString('en-GB', { month: 'short' });
    const lastMonth = lastDate.toLocaleDateString('en-GB', { month: 'short' });
  
    const firstYear = firstDate.getFullYear();
    const lastYear = lastDate.getFullYear();
  
    if (sameDay && sameMonth && sameYear) {
      return `${firstDayWithOrdinal} ${firstMonth} ${firstYear}`;
    } else if (sameMonth && sameYear) {
      return `${firstDayWithOrdinal} to ${lastDayWithOrdinal} ${lastMonth} ${firstYear}`;
    } else if (sameYear) {
      return `${firstDayWithOrdinal} ${firstMonth} to ${lastDayWithOrdinal} ${lastMonth} ${firstYear}`;
    } else {
      return `${firstDayWithOrdinal} ${firstMonth} ${firstYear} to ${lastDayWithOrdinal} ${lastMonth} ${lastYear}`;
    }
  };
  
  // Utility function to convert time from hh:mm:ss to seconds
  export const convertTimeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  
  // Utility function to convert seconds to hh:mm:ss
  export const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  
  // utils.js

export const fetchActivities = async () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${apiBaseUrl}/activities`);
  return response.json();
};

export const fetchActivityPoints = async () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${apiBaseUrl}/activity-points`);
  return response.json();
};

export const fetchTransports = async () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${apiBaseUrl}/transport`);
  return response.json();
};

export const fetchTransportActivities = async () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${apiBaseUrl}/transport-points`);
  return response.json();
};

export const processActivityData = (activities, activityPoints) => {
  return activities.map(activity => {
    const points = activityPoints
      .filter(point => point.activity_id === activity.id)
      .map(point => [point.lat, point.lon])
      .filter(point => point[0] !== undefined && point[1] !== undefined);

    return { ...activity, points };
  });
};

export const processTransportData = (transports, transportActivities) => {
  return transports.map(transport => {
    const points = transportActivities
      .filter(activity => activity.transport_id === transport.id)
      .map(activity => [activity.lat, activity.lon])
      .filter(point => point[0] !== undefined && point[1] !== undefined);

    return { ...transport, points };
  });
};

export const VeloVinoLink = ({ pathname, fromPage, className, children }) => {
  return (
    <Link 
      to={{ pathname: `${pathname}`, search: `?fromPage=${fromPage}` }}
      className={className}
    >
      {children}
    </Link>
  );
};