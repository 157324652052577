import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Custom component to fit map bounds based on the points
const FitBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (Array.isArray(points) && points.length > 0) {
      const validPoints = points.filter(point => Array.isArray(point) && point.length === 2);
      if (validPoints.length > 0) {
        const bounds = L.latLngBounds(validPoints);
        const paddingOptions = { padding: [0, 60] }; // Add padding on each side
        map.fitBounds(bounds, paddingOptions); 
      }
    }
  }, [map, points]);

  return null;
};

const MapRenderer = ({ markers = [], polylineGroups = [], popupContent }) => {
  return (
    <MapContainer style={{ height: "80vh", maxHeight: "600px", width: "100%" }}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />

      {/* Render markers */}
      {Array.isArray(markers) && markers.length > 0 && markers.map((marker, idx) => {
        if (marker && marker.position && marker.icon) {
          return (
            <Marker key={idx} position={marker.position} icon={marker.icon}>
              <Popup>
              {marker.imageUrl && <img src={marker.imageUrl} alt="Activity" />} {/* Display the image in the popup */}
              {marker.imageUrl && <br />} {/* Display the image in the popup */}
              {popupContent ? popupContent(marker) : <strong>{marker.name}</strong>}
              </Popup>              
            </Marker>
          );
        } else {
          console.warn(`Invalid marker at index ${idx}:`, marker);
          return null;
        }
      })}

      {/* Render polylines for each group with custom styles */}
      {Array.isArray(polylineGroups) && polylineGroups.length > 0 && polylineGroups.map((group, idx) => {
        const { points, color = "#003362", weight = 3, opacity = 0.8 } = group;
        return (
          <Polyline
            key={idx}
            positions={points.filter(point => Array.isArray(point) && point.length === 2)}
            color={color}
            weight={weight}
            opacity={opacity}
          />
        );
      })}

      <FitBounds points={polylineGroups.flatMap(group => group.points)} /> {/* Fit map bounds based on all points */}
    </MapContainer>
  );
};

export default MapRenderer;
