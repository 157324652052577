import React from 'react';
import ContactForm from './ContactForm';

const Contact = () => (
  <section id="contact" className="content">
    <div className='content-header'>
    </div>
    <div className='content-header-text'>
      Contact
    </div>
    <div className='contact-us'>
      <ContactForm />
      <div>
        <picture title='Mangawhai Heads Surf Beach'>
          <source srcSet="/images/MangawhaiBeach.webp" type="image/webp" />
          <source srcSet="/images/MangawhaiBeach.png" type="image/png" />
          <img src="/images/MangawhaiBeach.png" alt="Mangawhai Beach" className="contact-image" />
        </picture>
      </div>
      <div className='contact-full-height'></div>
    </div>
  </section>
);

export default Contact;
